import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
    EyeSlashIcon,
    EyeIcon,
} from "@heroicons/react/20/solid";

import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import axios from "axios";
//Importo Menu
import { MenuDesktop } from "./componets/Menu_desktop";
import { MenuMobile } from "./componets/Menu_mobile";
import { MenuNavigazioneMobile } from "./componets/Menu_navigazioneMobile";
import { MenuNavigazioneDesktop } from "./componets/Menu_navigazioneDesktop";
//Importo Alert
import { AlertRed, AlertGreen } from "./componets/Alert";
//Importo Autenticazione Token
import getAuthToken from "../features/auth/axiosSetup";

function Profilo() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // State
    const [id, setId] = useState("");
    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [ruolo, setRuolo] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [showCurrentPw, setShowCurrentPw] = useState(false);
    const [showNewPw, setShowNewPw] = useState(false);
    const [showConfirmPw, setShowConfirmPw] = useState(false);
    const [textRedAlert, setextRedAlert] = useState("");
    const [textGreenAlert, setextGreenAlert] = useState("");
    const [loadingAlertRed, setloadingAlertRed] = useState(false);
    const [loadingAlertGreen, setloadingAlertGreen] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isTheSame, setIsTheSame] = useState(true);

    //Gestione regex password (min.8 caratteri, almeno un carattere speciale, una lettera maiscola, una minuscola e un numero)
    const validatePassword = (value) => {
        // Verifica che la password non sia vuota prima di fare la validazione
        if (value.length === 0) {
            setIsValid(true);
            return; 
        }
        
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/;
        setIsValid(pattern.test(value));
    };

    //Funzioni per gestire il controllo password
    const handleControlPassword = (event) => {
        validatePassword(event.target.value);
    };

    const handleCurrentPassword = (event) => {
        setCurrentPassword(event.target.value);
    };

    const handleNewPassword = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPassword = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
    
        // Verifica se la conferma della password corrisponde a quella nuova
        if (value === newPassword) {
            setIsTheSame(true);
        } else {
            setIsTheSame(false);
        }
    };

    // Funzioni per toggle visibilità 
    const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPw(!showCurrentPw);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPw(!showNewPw);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPw(!showConfirmPw);
    };

    // Funzione per gestire il cambio della password
    const handleChangePassword = async () => {
        const token = await getAuthToken();

        try {
            const config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/users/update-password`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                data: {
                    currentPassword,
                    newPassword,
                    confirmPassword,
                    userId: id,
                }
            };

        const response = await axios.request(config);

        if (response.data.status === 200) {
            setloadingAlertGreen(true);
            setextGreenAlert("Password aggiornata con successo");
            // Reset form dopo un breve timeout
            setTimeout(() => {
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setloadingAlertGreen(false);
            }, 2000);
        }
        } catch (error) {
            if(error.response.data.status === 404) {
                setloadingAlertRed(true);
                setextRedAlert("Password attuale sbagliata");

                // Nascondi l'alert dopo un breve timeout
                setTimeout(() => {
                    setloadingAlertRed(false);
                }, 2000);
            } else if (error.response && error.response.data) {
                setloadingAlertRed(true);
                setextRedAlert("Errore nella modifica della password.");

                // Nascondi l'alert dopo un breve timeout
                setTimeout(() => {
                    setloadingAlertRed(false);
                }, 2000);
            }
        } 
    };

    // Funzione per cancellare i Cookie criptati
    function removeEncryptedCookie(key) {
        // Rimuove il cookie specificato
        Cookies.remove(key);
    };

    // Funzione per riprendere i cookie salvati
    function getDecryptedCookie(key) {
        try {
            const encryptedValue = Cookies.get(key);

            if (!encryptedValue) {
                return null;
            }
            // Decriptazione
            const bytes = CryptoJS.AES.decrypt(
                encryptedValue,
                process.env.REACT_APP_COOKIE_SECRET,
            );

            if (bytes) {
                const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

                if (decryptedValue) {
                    return decryptedValue;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    // Recupero le informazioni dai Cookie
    // Esegui il controllo una sola volta quando la pagina viene montata
    useEffect(() => {
        setId(getDecryptedCookie(process.env.REACT_APP_COOKIE_ID));
        setNome(getDecryptedCookie(process.env.REACT_APP_COOKIE_FNAME));
        setCognome(getDecryptedCookie(process.env.REACT_APP_COOKIE_LNAME));
        setRuolo(getDecryptedCookie(process.env.REACT_APP_COOKIE_ROLE));
        setEmail(getDecryptedCookie(process.env.REACT_APP_COOKIE_EMAIL));
        setUsername(getDecryptedCookie(process.env.REACT_APP_COOKIE_USERNAME));
    }, []);

    const handleLogout = () => {
        //Cancello i Cookiee
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_ID);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_FNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_LNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_ROLE);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_EMAIL);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_USERNAME);

        //Eseguo Logout
        dispatch(logout());
        navigate("/login");
    };

    const handleProfilo = () => {
        navigate("/profilo");
    };

    //Funzione per Generare l'immagine
    function generateInitialsImage(name, surname) {
        const initials = (name[0] || "") + (surname[0] || "");
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // Imposta le dimensioni del canvas
        const size = 80; // Dimensione dell'immagine
        canvas.width = size;
        canvas.height = size;

        // Imposta il colore di sfondo
        context.fillStyle = "#5930B6"; // Colore di sfondo (puoi personalizzarlo)
        context.fillRect(0, 0, size, size);

        // Imposta le proprietà del testo
        context.fillStyle = "#fff"; // Colore del testo
        context.font = "36px Roboto"; // Font e dimensione del testo

        context.textAlign = "center";
        context.textBaseline = "middle";

        // Disegna le iniziali al centro del canvas
        context.fillText(initials, size / 2, size / 2);

        // Ritorna l'URL dell'immagine generata
        return canvas.toDataURL("image/png");
    };

    // Genera l'immagine con le iniziali dell'utente
    const initialsImageUrl = generateInitialsImage(nome, cognome);

    // Funzione per ottenere anno
    const GetAnno = () => {
        return new Date().getFullYear();
    };
    
    return (
        <>
            <div className="flex flex-col min-h-screen bg-gray-100">
                {/* Menù navigazione */}
                <Disclosure as="nav" className="bg-white shadow-sm">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="block h-8 w-auto lg:hidden"
                                    />
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="hidden h-8 w-auto lg:block"
                                    />
                                </div>
                                <MenuNavigazioneDesktop
                                    ruolo={ruolo}
                                    navigate={navigate}
                                />
                            </div>

                            <div className="hidden xl:flex sm:ml-6 sm:items-center">
                                {/* Profile dropdown */}
                                <MenuDesktop
                                    nome={nome}
                                    cognome={cognome}
                                    ruolo={ruolo}
                                    email={email}
                                    initialsImageUrl={initialsImageUrl}
                                    handleLogout={handleLogout}
                                    handleProfilo={handleProfilo}
                                />
                            </div>

                            {/* Mobile Section */}
                            <div className="-mr-2 flex items-center xl:hidden">
                                {/* Mobile menu button */}
                                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    <Bars3Icon
                                        aria-hidden="true"
                                        className="block h-6 w-6 group-data-[open]:hidden"
                                    />
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="hidden h-6 w-6 group-data-[open]:block"
                                    />
                                </DisclosureButton>
                            </div>
                        </div>
                    </div>

                    {/* Mobile  */}
                    <DisclosurePanel className="xl:hidden">
                        <MenuNavigazioneMobile
                            ruolo={ruolo}
                            navigate={navigate}
                        />
                        <MenuMobile
                            nome={nome}
                            cognome={cognome}
                            ruolo={ruolo}
                            email={email}
                            initialsImageUrl={initialsImageUrl}
                            handleLogout={handleLogout}
                            handleProfilo={handleProfilo}
                        />
                    </DisclosurePanel>
                </Disclosure>

                {/* Contenuto sezione profilo */}
                <div className="flex-grow py-10 ">
                    <header>
                        <div className="xl:mx-auto md:mx-10 mx-6 max-w-7xl px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-5">
                            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                                Profilo
                            </h1>

                            <div className="mt-2">
                                <div>
                                    <div className="">
                                        <h3 className="text-base font-semibold leading-7 text-gray-900">
                                            Informazioni
                                        </h3>
                                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                                            Di seguito sono elencate tutte le
                                            voci associate a questo account.
                                        </p>
                                    </div>
                                    <div className="mt-6">
                                        <dl className="grid grid-cols-1 sm:grid-cols-2">
                                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Nome e Cognome
                                                </dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                                    {nome} {cognome}
                                                </dd>
                                            </div>
                                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Ruolo
                                                </dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                                    {ruolo === 'admin' ? 'Admin' : 'User'}
                                                </dd>
                                            </div>
                                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Indirizzo Email
                                                </dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                                    {email}
                                                </dd>
                                            </div>
                                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Nome Utente
                                                </dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                                    {username}
                                                </dd>
                                            </div>
                                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Permessi
                                                </dt>
                                                {ruolo == "admin" ? (
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                                        Gli utenti con ruolo di
                                                        Admin possiedono pieni
                                                        poteri e privilegi
                                                        all'interno del sistema,
                                                        inclusi l'accesso
                                                        completo a tutte le
                                                        funzionalità e la
                                                        capacità di gestire
                                                        risorse e impostazioni
                                                        senza restrizioni.
                                                    </dd>
                                                ) : (
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                                        Gli utenti con ruolo di
                                                        User hanno accesso
                                                        limitato alle
                                                        funzionalità del
                                                        sistema, con restrizioni
                                                        specifiche alle aree e
                                                        alle operazioni. Questo
                                                        ruolo consente di
                                                        interagire con le
                                                        risorse e le funzioni
                                                        essenziali, garantendo
                                                        un uso sicuro e conforme
                                                        alle autorizzazioni
                                                        assegnate.
                                                    </dd>
                                                )}
                                            </div>

                                            
                                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
                                                {loadingAlertRed ? (
                                                    <AlertRed
                                                        testo={
                                                            textRedAlert
                                                        }
                                                    />
                                                ) : null}
                                                {loadingAlertGreen ? (
                                                    <AlertGreen
                                                        testo={
                                                            textGreenAlert
                                                        }
                                                    />
                                                ) : null}

                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Password Attuale
                                                </dt>
                                                <dt className="text-xs font-medium leading-6 text-gray-500">
                                                    Inserisci la password attuale
                                                </dt>
                                                <dd className="mt-2 text-sm text-gray-900">
                                                    <div>
                                                        <div className="space-y-4">
                                                            <div>
                                                                <div>
                                                                    <div className="mt-1 relative">
                                                                        {/* Input password attuale */}
                                                                        <input
                                                                            onChange={handleCurrentPassword}
                                                                            value={currentPassword}
                                                                            id="currentPassword"
                                                                            name="currentPassword"
                                                                            type={
                                                                                showCurrentPw
                                                                                    ? "text"
                                                                                    : "password"
                                                                            } // Mostra/nasconde la password
                                                                            autoComplete="current-password"
                                                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                        />
                                                                        <span
                                                                            onClick={
                                                                                toggleCurrentPasswordVisibility
                                                                            }
                                                                            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-500"
                                                                        >
                                                                            {!showCurrentPw ? (
                                                                                <EyeSlashIcon
                                                                                    className="h-5 w-5 text-gray-400"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <EyeIcon
                                                                                    className="h-5 w-5 text-gray-400"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>
                                            <div className="border-gray-100 pt-1 pb-3 sm:col-span-2 sm:px-0 px-4 py-6">  
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Nuova Password
                                                </dt>
                                                <dt className="text-xs font-medium leading-6 text-gray-500">
                                                    Inserisci la nuova password
                                                </dt>
                                                <dd className="mt-2 text-sm text-gray-900">
                                                    <div>
                                                        <div className="space-y-4">
                                                            <div>
                                                            <div>
                                                                { !isValid && newPassword.length > 0 && (
                                                                    <span className="text-red-600 text-xs mb-1 block">
                                                                        La password deve essere lunga 8 caratteri
                                                                    </span>
                                                                )}
                                                                <div className=" relative">
                                                                    {/* Input Nuova password */}
                                                                    <input
                                                                        onChange={handleNewPassword}
                                                                        onInput={handleControlPassword}
                                                                        value={newPassword}
                                                                        id="newPassword"
                                                                        name="newPassword"
                                                                        type={showNewPw ? "text" : "password"} // Mostra/nasconde la password
                                                                        autoComplete="current-password"
                                                                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                    />

                                                                    {/* Icona per mostrare/nascondere la password */}
                                                                    <span
                                                                        onClick={toggleNewPasswordVisibility}
                                                                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-500"
                                                                    >
                                                                        {!showNewPw ? (
                                                                            <EyeSlashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                        ) : (
                                                                            <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>
                                            <div className="border-gray-100 px-4 py-3 sm:col-span-2 sm:px-0">  
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Conferma Nuova Password
                                                </dt>
                                                <dt className="text-xs font-medium leading-6 text-gray-500">
                                                    Conferma la nuova password
                                                </dt>
                                                <dd className="mt-2 text-sm text-gray-900">
                                                    <div>
                                                        <div className="space-y-4">
                                                            <div>
                                                                <div>
                                                                    {!isTheSame && confirmPassword.length > 0 && (
                                                                        <span className="text-red-600 text-xs">
                                                                            Le password non corrispondono
                                                                        </span>
                                                                    )}
                                                                    <div className="relative">
                                                                        <input
                                                                            onChange={handleConfirmPassword}
                                                                            value={confirmPassword}
                                                                            id="confirmPassword"
                                                                            name="confirmPassword"
                                                                            type={
                                                                                showConfirmPw
                                                                                    ? "text"
                                                                                    : "password"
                                                                            } // Mostra/nasconde la password
                                                                            autoComplete="current-password"
                                                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                        />
                                                                        <span
                                                                            onClick={
                                                                                toggleConfirmPasswordVisibility
                                                                            }
                                                                            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-500"
                                                                        >
                                                                            {!showConfirmPw ? (
                                                                                <EyeSlashIcon
                                                                                    className="h-5 w-5 text-gray-400"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <EyeIcon
                                                                                    className="h-5 w-5 text-gray-400"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <button
                                                                        onClick={() =>
                                                                            handleChangePassword()
                                                                        }
                                                                        className="flex mt-6 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                    >
                                                                        Modifica Password
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>

                {/* Footer */}
                <footer className="bg-white">
                    <div className="mx-auto px-6 sm:py-3 md:py-3 xl:py-5 items-center">
                        <div className="mt-4 md:order-1 md:mt-0">
                            <p className="text-center text-xs leading-5 text-gray-500">
                                {GetAnno()} © Centro del Sorriso, sviluppato
                                con il ❤️ da Tribus Informatica Srl
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Profilo;
