import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
} from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import axios from "axios";
//Importo Autenticazione Token
import getAuthToken from "../../features/auth/axiosSetup";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

import RadioComponent from "../componets/RadioComponet"

import DoneIcon from "../../assets/AnimatedIcon/doneIcon";
import CrossIcon from "../../assets/AnimatedIcon/crossIcon";
import ThreeStateCheckbox from "./CastomCheckBox";

const categories = [{option:'Non definito', cheked: false} , {option:'Fronte', cheked: false} , {option:'Sinistra', cheked: false} , {option:'Mandibolare', cheked: false} , {option:'Mascellare', cheked: false} , {option:'Destra', cheked: false} ];


const ModalImage = ({ src, patient, data, setData, checkboxState, setCheckboxState , imageType, approvati, nonApprovati, setImageType,  setSrc, open, setOpen }) => {

    const [shortcuts, setShortcuts] = useState({
        Save: "",
        deleteSave: "",
    });
    const [radioOptions, setRadioOptions] = useState(categories);
    const [totApprovati, setTotApprovati] = useState(approvati);
    const [totNonApprovati, setTotNonApprovati] = useState(nonApprovati);
    const [loadingProgres, setLoadingProgres] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [currentImage, setCurrentImage] = useState(1);

    const handleShortcutChange = (action, newShortcut) => {
        setShortcuts((prev) => ({
            ...prev,
            [action]: newShortcut,
        }));
    };

    // Funzione per riprendere i cookie salvati
    function getDecryptedCookie(key) {
        try {
            const encryptedValue = Cookies.get(key);
            if (!encryptedValue) {
                return null;
            }
            const bytes = CryptoJS.AES.decrypt(
                encryptedValue,
                process.env.REACT_APP_COOKIE_SECRET,
            );
            const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedValue ? decryptedValue : null;
        } catch (error) {
            return null;
        }
    }

    const loadConnection = async () => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/settings/get-setup.by-user`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: new URLSearchParams({
                    usernameGet: getDecryptedCookie(
                        process.env.REACT_APP_COOKIE_USERNAME,
                    ),
                }),
            };

            // Effettua la richiesta con axios
            const response = await axios.request(config);

            //Controllo la risposta e conseguneza setto gli state
            if (response.data.status === true) {
                setShortcuts(response.data.data.shortcut)
            } 
        } catch (error) {
            console.log("Errore nella richiesta:", error);
        }
    };

    // Funzione per spostarsi tra le immagini
    const navigateImages = (direction, satus) => {
        data.forEach((element) => {
            const url = element.url;
            element.src_images.forEach((e, index) => {
                if (`${url}/${e.path}` === src) {
                    const newIndex = index + direction;

                    // Blocca navigazione se si raggiunge la prima o l'ultima immagine
                    if (newIndex >= 0 && newIndex < element.src_images.length) {

                        setCurrentImage(index + direction + 1)

                        setIsLoading(true);
                        if(satus){
                            setLoadingProgres(true)
                            setCheckboxState(satus);
                            setTimeout(()=>{
                                setLoadingProgres(false);
                                setCheckboxState(element.src_images[newIndex].stato)
                                setSrc(`${url}/${element.src_images[newIndex].path}`);
                                setRadioOptions((prev) =>
                                    prev.map((item) => ({
                                        ...item,
                                        cheked: element.src_images[newIndex].type === item.option,
                                    }))
                                );
                            }, 2000)
                        }else{
                            setSrc(`${url}/${element.src_images[newIndex].path}`);
                            setCheckboxState(element.src_images[newIndex].stato)
                            //setImageType(element.src_images[newIndex].type)
                            setRadioOptions((prev) =>
                                prev.map((item) => ({
                                    ...item,
                                    cheked: element.src_images[newIndex].type === item.option,
                                }))
                            );
                            
                        }

                    }
                }
            });
        });
    };

    const handleShortcut = () => {

        let totApprovati = 0
        let totNonApprovati = 0

        setData(prev => 
            prev.map((item)=>{
                const url = item.url;
                const src_images = item.src_images.map((e, index) => {
                    if(e.stato === "approve"){
                        totApprovati++
                    }else if(e.stato === "notApprove" && `${url}/${e.path}` !== src){
                        totNonApprovati++
                    }
                    if (`${url}/${e.path}` === src && e.stato !== "approve") {
                        totApprovati++

                        return {...e, stato: "approve"}
                    }else{
                        return e
                    }
                });
                return {...item, src_images: src_images}
            })
        )
        setTotApprovati(totApprovati)
        setTotNonApprovati(totNonApprovati) 
        setCheckboxState("approve")

        //navigateImages(1 , "approve")

        
    };

    const handleShortcutx = () => {
        let totApprovati = 0
        let totNonApprovati = 0
        setData(prev => 
            prev.map((item)=>{
                const url = item.url;
                const src_images = item.src_images.map((e, index) => {
                    if(e.stato === "approve" && `${url}/${e.path}` !== src){
                        totApprovati++
                    }else if(e.stato === "notApprove"){
                        totNonApprovati++
                    }
                    if (`${url}/${e.path}` === src && e.stato !== "notApprove") {
                        totNonApprovati++

                        return {...e, stato: "notApprove"}
                    }else{
                        return e
                    }
                });
                return {...item, src_images: src_images}
            })
        )
        setTotApprovati(totApprovati )
        setTotNonApprovati(totNonApprovati) 
        setCheckboxState("notApprove")
        //navigateImages(1, "notApprove")
    };

    const handleRadioChange = (e) => {
        const selectedOption = e.target.value;
    
        setData((prev) =>
            prev.map((item) => {
                const updatedSrcImages = item.src_images.map((img) => {
                    const fullPath = `${item.url}/${img.path}`;
                    if (fullPath === src) {
                        return { ...img, type: selectedOption };
                    }
                    return img;
                });
                return { ...item, src_images: updatedSrcImages };
            })
        );
    
        setRadioOptions((prev) =>
            prev.map((option) => ({
                ...option,
                cheked: option.option === selectedOption,
            }))
        );
    };
    

    useEffect(() => {
        loadConnection()
        setRadioOptions(categories);
    }, []);
    
    useEffect(() => {
        setRadioOptions(prev => prev.map((i)=>{
            if(i.option === imageType)
            {
            return { ...i, cheked: true }
            } else {
                return { ...i, cheked: false }
            }
            }))
    }, [imageType]);

    // Aggiungi gestione tasti della tastiera
    useEffect(() => {
        const handleKeydown = (event) => {
            // Controlla per navigazione o chiusura
            if (event.key === "ArrowLeft") {
                navigateImages(-1); // Freccia sinistra
            } else if (event.key === "ArrowRight") {
                navigateImages(1); // Freccia destra
            } else if (event.key === "Escape") {
                setOpen(false); // Chiudi la modale con ESC
            }
    
            // Controlla shortcut dinamici
            Object.entries(shortcuts).forEach(([action, shortcut]) => {
                // Dividi shortcut in componenti
                const shortcutParts = shortcut.split("+").map(part => part.toLowerCase());
                // Controlla i modificatori
                const modifiersMatch = [
                    shortcutParts.includes("control") === event.ctrlKey,
                    shortcutParts.includes("shift") === event.shiftKey,
                    shortcutParts.includes("alt") === event.altKey,
                ].every(Boolean);
                // Controlla il tasto principale
                const mainKey = shortcutParts.find(part => !["control", "shift", "alt"].includes(part));
                const keyMatch = mainKey === event.key.toLowerCase();
    
                if (modifiersMatch && keyMatch) {
                    event.preventDefault(); // Previeni comportamento predefinito
    
                    // Esegui l'azione corrispondente
                    if (action === "Save") {
                        handleShortcut();
                    } else if (action === "deleteSave") {
                        handleShortcutx();
                    }
                }
            });
        };
    
        if (open) {
            window.addEventListener("keydown", handleKeydown);
        }
    
        return () => {
            window.removeEventListener("keydown", handleKeydown);
        };
    }, [open, shortcuts, src, data , radioOptions]);


    return (
        <Dialog open={open} onClose={setOpen} className="relative z-10">
            <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center text-center sm:p-0">
                <DialogPanel className="relative transform">
                    <div className="relative w-full h-full grid grid-cols-[auto_1fr_auto] items-center">

                        {/* Pulsante sinistro */}
                        <button
                            onClick={() => navigateImages(-1)} // Navigazione indietro
                            className={`mr-10 w-16 h-16 flex justify-center items-center bg-blue-100 p-3 rounded-full text-sm font-semibold col-span-1 ${
                                data.some((element) => {
                                    const url = element.url;
                                    return element.src_images[0] &&
                                        `${url}/${element.src_images[0].path}` === src;
                                })
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                            }`}
                            disabled={data.some((element) => {
                                const url = element.url;
                                return element.src_images[0] &&
                                    `${url}/${element.src_images[0].path}` === src;
                            })}
                        >
                            <ChevronLeftIcon aria-hidden="true" className="w-full h-full text-[#c315a2]" />
                        </button>

                        {/* Immagine */}
                        <div className="relative flex flex-col justify-center items-center">


                            <div className="w-full bg-indigo-100 bg-opacity-80  px-2.5 py-2 my-2  rounded-lg">
                                <p className="font-semibold text-indigo-700 text-2xl">
                                    {patient.name} {patient.surname}
                                </p>
                                <p className="italic">
                                    Immagine{" "}
                                    <span className="text-indigo-700 font-semibold italic">{currentImage}</span> di{" "}
                                    <span className="text-indigo-700 font-semibold italic">{patient.src_images.length}</span>

                                </p>
                                <div className="w-full  flex gap-6 justify-center py-1">
                                    {/* Pulsante Done (Verde) */}
                                    <p className="">
                                        Approvati{" : "}
                                        <span className="text-green-500 font-semibold italic">{totApprovati}</span> 
                                    </p>

                                    {/* Pulsante Cancelled (Rosso) */}
                                    <p className="">
                                        Non Approvati{" : "}
                                        <span className="text-red-500 font-semibold italic">{totNonApprovati}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="w-full bg-indigo-100 bg-opacity-80  px-2.5 py-2 my-2  rounded-lg">
                                    <RadioComponent
                                        options={radioOptions}
                                        name="position"
                                        onChange={handleRadioChange}
                                    />

                            </div>



                            <div className="relative w-auto h-auto max-w-full max-h-[70vh] overflow-hidden rounded-lg shadow-xl">

                                
                                                        {/* Striscia del Checkbox con background opaco */}
                            {checkboxState === "approve" || checkboxState === "notApprove" ? (
                                <div
                                    className={`absolute tendina-visible top-0 right-0 z-10 w-full h-14 
                                        ${
                                            checkboxState === "approve"
                                                ? "bg-green-700 text-green-200"
                                                : checkboxState === "notApprove"
                                                ? "bg-red-700 text-red-200"
                                                : "bg-gray-700 text-gray-200"
                                        }
                                        bg-opacity-50 gap-5 flex items-center justify-center`}
                                >
                                    <p className="italic">
                                        {checkboxState === "approve"
                                            ? <>Approvato</> // Verde se checked
                                            : checkboxState === "notApprove"
                                            ? "Non Approvato" // Rosso se unchecked
                                            : "Da valutare" // Neutro
                                        }
                                    </p>
                                </div>
                            ) : null}


                                {isLoading && (
                                    <div className="absolute inset-0 flex justify-center items-center bg-gray-200 bg-opacity-75 animate-pulse">
                                        <span className="text-gray-500">Caricamento...</span>
                                    </div>
                                )}
                                <img
                                    src={src}
                                    alt={"Immagine paziente"}
                                    loading="lazy"
                                    className={`w-auto h-auto max-w-full max-h-[70vh] overflow-hidden rounded-lg shadow-xl object-contain image-loaded ${
                                        isLoading ? 'opacity-0' : 'opacity-100'
                                    } transition-opacity duration-300`}
                                    onLoad={() => setIsLoading(false)}
                                />
                            </div>


                                                {/* Sezione pulsanti per "Done" e "Cancel" */}
                    <div className="w-full  flex gap-6 justify-center py-2">
                        {/* Pulsante Done (Verde) */}
                        <button
                            type="button"
                            onClick={handleShortcut}
                            className="flex items-center justify-center w-full rounded-md bg-green-500 px-6 py-2 text-lg font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            <CheckIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                            Approvato
                        </button>

                        {/* Pulsante Cancelled (Rosso) */}
                        <button
                            type="button"
                            onClick={handleShortcutx}
                            className="flex items-center justify-center w-full rounded-md bg-red-500 px-6 py-2 text-lg font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        >
                            <XMarkIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                            Non approvato
                        </button>
                    </div>
                        </div>

                        {/* Pulsante destro */}
                        <button
                            onClick={() => navigateImages(1)} // Navigazione avanti
                            className={`ml-10 w-16 h-16 flex justify-center items-center bg-blue-100 p-3 rounded-full text-sm font-semibold col-span-1 ${
                                data.some((element) => {
                                    const url = element.url;
                                    return element.src_images[element.src_images.length - 1] &&
                                        `${url}/${element.src_images[element.src_images.length - 1].path}` === src;
                                })
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                            }`}
                            disabled={data.some((element) => {
                                const url = element.url;
                                return element.src_images[element.src_images.length - 1] &&
                                    `${url}/${element.src_images[element.src_images.length - 1].path}` === src;
                            })}
                        >
                            <ChevronRightIcon aria-hidden="true" className="w-full h-full text-[#c315a2]" />
                        </button>

                    </div>



                </DialogPanel>

                </div>
            </div>
        </Dialog>
    );
};

export default ModalImage;
