'use client'

import { useState } from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import ModalImageSend from "../componets/ModalImageSend"
import getAuthToken from "../../features/auth/axiosSetup";
import axios from "axios";

export default function Example({open, setOpen , Selectedpatient , imageToMouve , patients , getAllConnection}) {

 const [ModaleOpen, SetModaleOpen] = useState(false);
 const [Topatient, SetTopatient] = useState({});
      //Funzione per Generare l"immagine
  function generateInitialsImage(name, surname) {
    const initials = (name[0] || "") + (surname[0] || "");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Imposta le dimensioni del canvas
    const size = 80; // Dimensione dell"immagine
    canvas.width = size;
    canvas.height = size;

    // Imposta il colore di sfondo
    context.fillStyle = "#5930B6"; // Colore di sfondo (puoi personalizzarlo)
    context.fillRect(0, 0, size, size);

    // Imposta le proprietà del testo
    context.fillStyle = "#fff"; // Colore del testo
    context.font = "36px Urbanist, sans-serif"; // Font e dimensione del testo

    context.textAlign = "center";
    context.textBaseline = "middle";

    // Disegna le iniziali al centro del canvas
    context.fillText(initials, size / 2, size / 2);

    // Ritorna l"URL dell"immagine generata
    return canvas.toDataURL("image/png");
    }

    const SendeAndOrderImg = async ()=>{
        try {
          // Ottieni il token in modo asincrono
          const token = await getAuthToken();
    
          // Configura la richiesta
          let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_URL_API}/patients/send-patient-img`,
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: `Bearer ${token}`, // Usa il token ottenuto
              },
              data: { 
                patient_id: Selectedpatient._id,
                patientTO_id: Topatient._id,
                img_id: imageToMouve.id,
              }
          };
    
          // Effettua la richiesta con axios
          const response = await axios.request(config);
    
          //Controllo la risposta e conseguneza setto gli state
          if (response.data.success) {
            getAllConnection(Selectedpatient , Topatient, imageToMouve.id);
            SetModaleOpen(false);
            setOpen(false);
          }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
        }
      }


  return (<>
        <Dialog open={open} onClose={setOpen} className="relative z-10">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                >
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                        <DialogTitle className="text-base font-semibold text-gray-900">Invia Foto</DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                        <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon aria-hidden="true" className="size-6" />
                        </button>
                        </div>
                    </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                            <img
                                alt={`item`}
                                src={imageToMouve.url}
                                loading="lazy"
                                id={imageToMouve.id}
                                className="content-img object-cover bottom-0 left-0 rounded-md overflow-hidden outline-dashed outline-1 outline-offset-8 overflow-hidden"
                            />


                            <ul role="list" className="divide-y divide-white/5 py-5">
                                {patients.filter((patient)=>patient._id !== Selectedpatient._id).map((patient) => (
                                    <li key={patient._id} className={`px-2 py-2 hover:bg-indigo-500/20 cursor-pointer `}
                                    onClick={()=>{
                                        SetTopatient(patient);
                                        SetModaleOpen(true)
                                    }}
                                    >
                                        <div className="flex items-center gap-x-3 justify-between ">
                                            {/* Sinistra: Contenuto principale */}
                                            <div className="flex items-center gap-x-3 ">
                                                <img
                                                    alt=""
                                                    src={generateInitialsImage(patient.name, patient.surname)}
                                                    className="size-10 flex-none rounded-full bg-gray-800"
                                                />
                                                <h3 className="flex-auto truncate text-base font-semibold text-gray-600">
                                                    {patient.name} {patient.surname}
                                                </h3>
                                            </div>

                                        </div>
                                    </li>

                                ))}
                            </ul>
                    </div>
                </div>
                </DialogPanel>
            </div>
            </div>
        </div>
        </Dialog>

        <ModalImageSend patient={Topatient} open={ModaleOpen} setOpen={SetModaleOpen} callBackSend={SendeAndOrderImg} />                       
    </>)
}
