import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // usa il local storage come storage
import { combineReducers } from 'redux';
import authReducer from '../features/auth/authSlice';
import sessionReducer from '../features/auth/sessionSlice';
import tokenReducer from '../features/auth/tokenSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  session: sessionReducer,
  token: tokenReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    "token",
    "session"
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

