import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  session: false,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action) => {
      state.session = action.payload;
    },
    removeSession: (state) => {
      state.session = false;
    },
  },
});

export const { setSession, removeSession } = sessionSlice.actions;
export default sessionSlice.reducer;
