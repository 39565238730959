import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import { useDispatch, useSelector } from "react-redux";
//Importo Autenticazione Token
import getAuthToken from "../../features/auth/axiosSetup";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { setSession } from "../../features/auth/sessionSlice";
import { logout } from "../../features/auth/authSlice";


const Modal = ({ isOpen, onClose , ModalData, group, Selectedpatient , getAllPresentation }) => {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stored_token = useSelector((state) => state.token.token);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const stored_session = useSelector((state) => state.session.session);

  const hasEmptyData = (array) => {
      return array.some(item => Array.isArray(item.data) && item.data.length === 0);
  };

  const checkToken = async () => {
    const URL_API = process.env.REACT_APP_URL_API;

    const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${URL_API}/tokens/check`,
        headers: {
            "Content-Type": "application/json"
        },
        data: {
            token: stored_token
        }
    };

    try {
        const response = await axios.request(config);
    
        if (response.data.status) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
  };

  // Funzione per cancellare i Cookie criptati
  function removeEncryptedCookie(key) {
      // Rimuove il cookie specificato
      Cookies.remove(key);
  };

  const handleLogout = () => {
      //Cancello i Cookiee
      removeEncryptedCookie(process.env.REACT_APP_COOKIE_FNAME);
      removeEncryptedCookie(process.env.REACT_APP_COOKIE_LNAME);
      removeEncryptedCookie(process.env.REACT_APP_COOKIE_ROLE);
      removeEncryptedCookie(process.env.REACT_APP_COOKIE_EMAIL);
      removeEncryptedCookie(process.env.REACT_APP_COOKIE_USERNAME);

      dispatch(setSession(true));

      //Eseguo Logout
      dispatch(logout());
      navigate("/login");
  };

  const createPresentation = async () => {
    try {
        // Ottieni il token in modo asincrono
        const token = await getAuthToken();
        const Ids = ModalData.map((item)=>{return item.idShowen});

        // Configura la richiesta
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_URL_API}/presentation/create`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // Usa il token ottenuto
            },
            data: {
                patient_id: Selectedpatient._id,
                Ids: Ids,
                group: group,
              },
        };

        const status_token = await checkToken();

        if (status_token) {

            // Effettua la richiesta con axios
            const response = await axios.request(config);

            // Controllo la risposta
            if (response.data.status === true) {
                // Setta lo state con i pazienti
                getAllPresentation(Selectedpatient);
                onClose(false);
            } else {
                // Gestisci il caso in cui la risposta abbia status false
                console.log("Errore nella risposta:", response.data.message);
                // Potresti anche gestire il caso di errore impostando un messaggio di stato o un alert
            }
        } else {
            handleLogout();
        }
    } catch (error) {
        console.log("Errore nella richiesta:", error);
        // Gestione dell"errore, ad esempio aggiornando lo stato per mostrare un messaggio all"utente
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 ">
      <div className="bg-white  h-11/12 rounded-lg flex flex-col items-center justify-center p-5 relative">
        <button 
          className="absolute top-4 right-4 text-black text-2xl"
          onClick={()=> onClose(false)}
        >
          X
        </button>
        <h2 className='pb-4'>
          Seleziona immagini per lo slide
        </h2>
        <div className="flex flex-col justify-center items-center h-full w-full">
          {/* Riga superiore: 3 immagini */}
          <div className="flex gap-6 justify-center mb-8">
            {ModalData.filter(data => data.type !== "Mandibolare" && data.type !== "Mascellare").map((image, index) => (<>
              <div key={index} className="relative w-1/3"> {/* Modificato da w-1/3 a w-1/4 */}
                <div className="relative w-full mb-2">
                  <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm text-gray-500">{image.type}</span>
                  </div>
                </div>
                <div className='flex justify-between gap-3'>


                    <div className="h-auto flex items-center">
                      <button
                        className="w-16 h-16 flex justify-center items-center bg-blue-100 p-3 rounded-full text-sm font-semibold"
                        onClick={()=>{image.showPreviousImage()}}
                      >
                            <ChevronLeftIcon  aria-hidden="true" className=" w-full h-full text-[#c315a2] " />
                      </button>
                    </div>

                    {image.urlToShow.length < 80 ?
                      <div className=" inset-0 w-[25em] min-h-64 flex justify-center items-center bg-gray-200 bg-opacity-75">
                          <span className="text-gray-500">Nessun immagine trovata!</span>
                      </div>
                    :
                      <img 
                          src={image.urlToShow} 
                          alt={`image ${index}`} 
                          className="w-[25em] h-auto rounded-lg shadow-lg object-cover" 
                          onError={(e) => { e.target.src = "/images/not-found.png"; e.target.onerror = null; }} 
                      />
                    }


                    <div className="h-auto flex items-center">
                      <button
                        className="w-16 h-16 flex justify-center items-center bg-blue-100 p-3 rounded-full text-sm font-semibold"
                        onClick={()=>{image.showNextImage()}}
                      >
                        <ChevronRightIcon aria-hidden="true" className="w-full h-full text-[#c315a2]" />
                      </button>
                    </div>
                </div>
              </div>

              {index !== ModalData.filter(data => data.type !== "Mandibolare" && data.type !== "Mascellare").length-1 ?
                <div className="relative flex items-center">
                  <div aria-hidden="true" className="absolute inset-y-0 left-1/2 flex items-center">
                      <div className="h-full border-l border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm text-gray-500 transform -rotate-90">
                      
                    </span>
                  </div>
                </div>
              :
              null}
              </>))}
          </div>
          {/* Riga inferiore: 2 immagini */}

          <div className="flex gap-4 justify-center">
            {ModalData.filter(data => data.type === "Mandibolare" || data.type === "Mascellare").map((image, index) => (<>

              <div key={index} className="relative  w-1/2"> {/* Modificato da w-1/2 a w-1/3 */}
              
              <div className="relative w-full mb-2">
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500">{image.type}</span>
                </div>
              </div>

              <div className='flex justify-between gap-3'>

                  <div className="h-auto flex items-center  ">
                      <button
                        className="w-16 h-16 flex justify-center items-center bg-blue-100 p-3 rounded-full text-sm font-semibold"
                        onClick={()=>{image.showPreviousImage()}}
                      >
                            <ChevronLeftIcon  aria-hidden="true" className=" w-full h-full text-[#c315a2] " />
                      </button>
                  </div>

                    {image.urlToShow.length < 80 ?
                      <div className=" inset-0 w-[25em] min-h-64 flex justify-center items-center bg-gray-200 bg-opacity-75">
                          <span className="text-gray-500">Nessun immagine trovata!</span>
                      </div>
                    :
                      <img 
                          src={image.urlToShow} 
                          alt={`image ${index + 3}`} 
                          className="w-[25em] h-auto rounded-lg shadow-lg object-cover" 
                          onError={(e) => { e.target.src = "/images/not-found.png"; e.target.onerror = null; }} 
                      />
                    }


                  <div className="h-auto flex items-center">
                    <button
                      className="w-16 h-16 flex justify-center items-center bg-blue-100 p-3 rounded-full text-sm font-semibold"
                      onClick={()=>{image.showNextImage()}}
                    >
                      <ChevronRightIcon aria-hidden="true" className="w-full h-full text-[#c315a2]" />
                    </button>
                  </div>

              </div>




              </div>
              {index !== ModalData.filter(data => data.type === "Mandibolare" || data.type === "Mascellare").length-1 ?
              <div className="relative flex items-center">
                  <div aria-hidden="true" className="absolute inset-y-0 left-1/2 flex items-center">
                  <div className="h-full border-l border-gray-300"></div>
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500 transform -rotate-90">
                    
                  </span>
                </div>
              </div>
              :
              null}

              </>))}
          </div>

          <div className="mt-4 rounded-lg bg-white p-2 text-center flex items-center ">
            <button
              className={` w-full rounded ${hasEmptyData(ModalData) ? 'bg-[#910F79]' : 'bg-[#c315a2] hover:bg-[#e645c7]'}  px-4 py-2 text-white `}
              disabled={hasEmptyData(ModalData)}
              onClick={()=>{createPresentation()}}
            >
              Salva selezione
            </button>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Modal;
