import { useRef, useState } from 'react';

import { InboxIcon } from "@heroicons/react/20/solid";

export default function Example({ file, setFile , handleFileUpload , titale, title_mobile }) {
    // const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);

    // Gestione dell'evento di drag
    const handleDragOver = (e) => {
        e.preventDefault(); // Necessario per permettere il drop
        setIsDragging(true); // Cambia lo stato per evidenziare l'area
    };

    const handleDragLeave = () => {
        setIsDragging(false); // Rimuove l'evidenziazione quando l'elemento esce
    };

    const handleButtonClick = () => {
            fileInputRef.current.click();
      };

    const handleDrop = (e) => {
        e.preventDefault();  // Prevenire il comportamento di default del browser
        setIsDragging(false); // Rimuove l'evidenziazione del drag
      
        const droppedFile = e.dataTransfer.files[0]; // Ottieni il primo file dall'evento di drop
        if (droppedFile) {
            // Chiama handleFileUpload direttamente con il file
            const isFileValid = handleFileUpload(droppedFile);
            if(isFileValid){
              setFile(droppedFile); // Imposta lo stato del file
            }
        }
      };
      

    // Gestione dell'evento di selezione del file tramite file input
    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
        const isFileValid = handleFileUpload(selectedFile)

        if (selectedFile && isFileValid) {
            setFile(selectedFile);
        }
    };

    return (
        <>

                <button
                    type="button"
                    className={`relative block w-full ${isDragging ? 'border-indigo-600' : 'border-indigo-300'} p-12 text-center hover:border-indigo-400 focus:outline-none flex flex-col justify-center items-center`}
                    onClick={handleButtonClick}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {file ? (
                        <>
                            <p className="text-gray-600 font-semibold">File caricato:</p>
                            <p className="text-indigo-500 mt-2">{file.name}</p>
                        </>
                    ) : (
                        <>
                            <InboxIcon width={64} height={64} className="text-indigo-500" />
                            <p className="mt-3 hidden xl:flex font-semibold text-center text-gray-600">
                                {titale}
                            </p>
                            <p className="mt-3 xl:hidden flex font-semibold text-center text-gray-600">
                                {title_mobile}
                            </p>
                        </>
                    )}
                </button>


                {/* Aggiunta del file input per la selezione tramite clic */}
                <input
                    type="file"
                    onChange={handleFileSelect}
                    ref={fileInputRef}
                    className="hidden"
                    id="fileInput"
                />

        </>
    );
}