import { useState, useEffect, Fragment } from "react";
import { ChevronLeftIcon, ChevronRightIcon , UsersIcon} from "@heroicons/react/24/outline";

const ContainerImages = ({ columns, item, setSrc, setOpenModalImage , onClick }) => {
    const url = item.url || "";
    const src_images = item.src_images || [];
    const images_per_page = 5;
    const [images, setImages] = useState([]);
    const [current_page, setCurrentPage] = useState(0);
    const [total_pages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
        setImages([]);
        // Calcola il numero totale di pagine
        const totalPages = Math.ceil(src_images.length / images_per_page);
        setTotalPages(totalPages);

        // Ottieni le immagini per la pagina corrente
        const images_to_show = src_images.slice(
            current_page * images_per_page,
            (current_page + 1) * images_per_page
        );
        setImages(images_to_show);
        setLoading(true);
    }, [images_per_page, current_page, src_images]);

    // Funzione per cambiare pagina
    const handlePrev = () => {
        if (current_page > 0) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const handleNext = () => {
        if (current_page < total_pages - 1) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    return (<>

        <tr>
            <td
                colSpan={columns.length}
                className="w-full overflow-x-auto px-10 py-4 text-sm text-gray-900 text-center whitespace-nowrap shadow-inner bg-gray-100"
            >
                <div className="w-full pb-5">
                    <p className="text-gray-700 italic">
                    <span className="text-indigo-600 font-semibold  italic ">{src_images.length}</span> risultati
                    </p>
                </div>
                <div className="w-full grid grid-cols-12 justify-center items-center gap-4">
                    {/* Freccia Prev */}
                    <button
                        onClick={handlePrev}
                        disabled={current_page === 0}
                        className={`px-3 py-2 rounded-md text-sm font-semibold col-span-1 ${
                            current_page === 0 ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                    >
                        <ChevronLeftIcon
                            width={32}
                            height={32}
                            aria-hidden="true"
                            className="text-indigo-600 hover:text-indigo-500"
                        />
                    </button>

                    {/* Contenitore delle immagini o Loader */}
                    <div className="col-span-10 flex flex-row justify-center items-center">
                        {loading ? (
                            images.map((src_image, image_index) => (<>
                                    <div
                                        key={`image_${image_index}`}

                                        className="relative w-48 h-32 rounded-lg overflow-hidden shadow-md cursor-pointer mx-4"
                                        onClick={(e) => {
                                            if(e.target.id === src_image.path){
                                                setSrc(`${url}/${src_image.path}`);
                                                setOpenModalImage(true);
                                            }
                                            
                                        }}
                                    >
                                        <img
                                            alt={`Image preview ${image_index}`}
                                            id={src_image.path}
                                            src={`${url}/compressioni/${src_image.path}`}
                                            loading="lazy"
                                            className="w-full h-full object-cover image-loaded"
                                        />

                                        {/* ✅ Bottone posizionato in alto a destra */}
                                        <button
                                            className="absolute top-1 right-1 rounded-full bg-indigo-600 p-1 text-white shadow-md hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 z-2"
                                            onClick={(e)=>{onClick(e, url , src_image.path , src_image._id , item)}}
                                        >
                                            <UsersIcon className="h-5 w-5" />
                                        </button>
                                    </div>

                                </>))
                        ) : (
                            <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" role="status">
                                <span className="sr-only">Caricamento...</span>
                            </div>
                        )}
                    </div>

                    {/* Freccia Next */}
                    <button
                        onClick={handleNext}
                        disabled={current_page === total_pages - 1}
                        className={`px-3 py-2 rounded-md text-sm font-semibold col-span-1 ${
                            current_page === total_pages - 1 ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                    >
                        <ChevronRightIcon
                            width={32}
                            height={32}
                            aria-hidden="true"
                            className="text-indigo-600 hover:text-indigo-500"
                        />
                    </button>
                </div>

                <div className="w-full pt-5" >
                    <p className="text-gray-700 italic">
                    Pagina <span className="text-indigo-600 font-semibold  italic ">{current_page + 1}</span> di <span className="text-indigo-600 font-semibold  italic ">{total_pages}</span>
                    </p>
                </div>
            </td>
        </tr>



        </>);
};

export default ContainerImages;
