import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel
} from "@headlessui/react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,

} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
    PlusCircleIcon,
    PencilSquareIcon,
    TrashIcon,
    WindowIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    InboxArrowDownIcon,
    ArrowUpTrayIcon,
} from "@heroicons/react/20/solid";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

import { setSession } from "../features/auth/sessionSlice";
import { setToken } from "../features/auth/tokenSlice";

import { FileUploader } from "react-drag-drop-files";
import { InboxIcon, XCircleIcon } from "@heroicons/react/20/solid";

// CSS
import styles from "./styles.module.css";

//Importo componenti
import TablePatients from "./componets/TablePatients";

//Importo Menu
import { MenuDesktop } from "./componets/Menu_desktop";
import { MenuMobile } from "./componets/Menu_mobile";
import { MenuNavigazioneMobile } from "./componets/Menu_navigazioneMobile";
import { MenuNavigazioneDesktop } from "./componets/Menu_navigazioneDesktop";

// Tooltip
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

//Importo Autenticazione Token
import getAuthToken from "../features/auth/axiosSetup";

//Importo Alert
import { AlertRed, AlertGreen } from "./componets/Alert";
import moment from "moment";
import axios from "axios";
import ModalCode from "./componets/ModalCode";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Papa from 'papaparse';

import DragAndDrop from "./upload_wizard_pazienti/DragAndDrop";
import Galleria from "./componets/Galleria";
import PowerPoint from './componets/PowerPoint';
import PresentationModale from "./componets/presentation";
import PptxGenJS from "pptxgenjs";
import PowerPointIcon from "../assets/PowerPoint"
import PdfIcon from "../assets/PdfIcon"
import jsPDF from "jspdf";


function Pazienti() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const modal_ref = useRef(null);

    const stored_token = useSelector((state) => state.token.token);

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const stored_session = useSelector((state) => state.session.session);

    //Dichiaro gli State
    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [ruolo, setRuolo] = useState("");
    const [email, setEmail] = useState("");
    const [patients, setPatients] = useState([{}]); 
    const [studios, setStudios] = useState([{}]); 
    const [selectedStudio, setSelectedStudio] = useState("");
    
    const [inputSearch, setInputSearch] = useState("");
    const [presentations, setPresentations] = useState([]); 
    const [presentationsCopy, setPresentationsCopy] = useState([]); 
    const [presentationsLoading, setPresentationsLoading] = useState(false); 
    const [presentationsLoadingCopy, setPresentationsLoadingcopy] = useState(false); 
    const [loadingPatients, setLoadingPatients] = useState(false); 
    const [open, setopen] = useState(false); 
    const [Selectedpatient, setSelectedpatient] = useState({});

    const [textGreenAlert, setTextGreenAlert] = useState("");
    const [loadingAlertGreen, setLoadingAlertGreen] = useState(false);

    const [settings, setSettings] = useState('');

    const [ModalData, setModaldata] = useState([]);
    const [group, setGroup] = useState("");
    const [groups, setGroups] = useState([]);

    const [image , setImage] = useState()

    // Funzione per cancellare i Cookie criptati
    function removeEncryptedCookie(key) {
        // Rimuove il cookie specificato
        Cookies.remove(key);
    };

    // Funzione per riprendere i cookie salvati
    function getDecryptedCookie(key) {
        try {
            const encryptedValue = Cookies.get(key);

            if (!encryptedValue) {
                return null;
            }
            // Decriptazione
            const bytes = CryptoJS.AES.decrypt(
                encryptedValue,
                process.env.REACT_APP_COOKIE_SECRET,
            );

            if (bytes) {
                const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

                if (decryptedValue) {
                    return decryptedValue;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    // Esegui il controllo una sola volta quando la pagina viene montata
    useEffect(() => {
        setNome(getDecryptedCookie(process.env.REACT_APP_COOKIE_FNAME));
        setCognome(getDecryptedCookie(process.env.REACT_APP_COOKIE_LNAME));
        setRuolo(getDecryptedCookie(process.env.REACT_APP_COOKIE_ROLE));
        setEmail(getDecryptedCookie(process.env.REACT_APP_COOKIE_EMAIL));
    }, []);

    const handleLogout = () => {
        //Cancello i Cookiee
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_FNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_LNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_ROLE);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_EMAIL);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_USERNAME);

        dispatch(setSession(true));

        //Eseguo Logout
        dispatch(logout());
        navigate("/login");
    };

    const handleProfilo = () => {
        navigate("/profilo");
    };

    const checkToken = async () => {
        const URL_API = process.env.REACT_APP_URL_API;
    
        const config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${URL_API}/tokens/check`,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                token: stored_token
            }
        };

        try {
            const response = await axios.request(config);
        
            if (response.data.status) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    };

    // funzione per ricavare il buz64 delle immagini
    const getImage = async (image)=>{
        try {
        // Ottieni il token in modo asincrono
        const token = await getAuthToken();

        // Configura la richiesta
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_URL_API}/patients/Get-img`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${token}`, // Usa il token ottenuto
            },
            params: { 
                image: image, 
            }
        };

        // Effettua la richiesta con axios
        const response = await axios.request(config);

        //Controllo la risposta e conseguneza setto gli state
        if (response.data.success) {
            //Nessuna configurazione trovata stampo badge e diabilito configurazione
            setImage(response.data.img)
        }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
        }
    }
    // funzione per generare file power point
    const generatePresentation1 = () => {
        const pptx = new PptxGenJS();

        // Slide 2: Immagini (layout simile)
        const slide = pptx.addSlide();


        // Aggiungi altre 5 immagini segnaposto

        const slideWidth = 10; // Larghezza della slide (in pollici, standard di PptxGenJS)
        const margin = slideWidth * 0.01; // Calcolo del margine del 5%

        // Calcolo della larghezza di ciascuna immagine
        const imageWidth = (slideWidth - 2 * margin) / 3;

        // Posizioni x delle immagini
        const positionsX = [
        0, // Prima immagine
        margin + imageWidth, // Seconda immagine
        2 * margin + 2 * imageWidth, // Terza immagine
        ];

        const marginBottomImage = slideWidth * 0.02; // Calcolo del margine del 5%
        const imageWidthBottomImages = (slideWidth - 3 * marginBottomImage) / 2;

        const positionsXBottomImage = [
            marginBottomImage, // Prima immagine
            (marginBottomImage * 2) + imageWidthBottomImages, // Seconda immagine
        ];
        const imageBase64 = `data:image/jpeg;base64,${image.toString("base64")}`; 
        slide.addImage({ data: imageBase64, x: positionsX[0], y: 0.2, w: imageWidth, h: 2 })
        slide.addImage({ data: imageBase64, x: positionsX[1], y: 0.2, w: imageWidth, h: 2 });
        slide.addImage({ data: imageBase64, x: positionsX[2], y: 0.2, w: imageWidth, h: 2 });
        slide.addImage({data: imageBase64,  x: positionsXBottomImage[0], y: 2.5, w: imageWidthBottomImages, h: 2.5 });
        slide.addImage({data: imageBase64,  x: positionsXBottomImage[1], y: 2.5, w: imageWidthBottomImages, h: 2.5 });

        // Salva il file
        pptx.writeFile({ fileName: "Caso_Clinico_Slides.pptx" });
    }; 
    
    const generatePresentation2 = () => {
        const pptx = new PptxGenJS();
    
        // Imposta il layout della presentazione a 16:9 (13.3 x 7.5 pollici)
        pptx.layout = "LAYOUT_WIDE"; // Formato widescreen 16:9
    
        const slideWidth = 13.3; // Larghezza della slide in pollici (16:9)
        const slideHeight = 7.5; // Altezza della slide in pollici
    
        const margin = slideWidth * 0.01; // Margine del 1%
        const topMargin = 0.2; // Margine superiore
    
        // **Calcolo delle altezze**
        const imageHeightTop = slideHeight * 0.25; // 25% dell'altezza della slide
        const imageHeightBottom = slideHeight * 0.40; // 40% dell'altezza della slide
    
        // **Calcolo larghezze**
        const imageWidthTop = (slideWidth - 2 * margin) / 3; // 3 immagini in fila
        const marginBottomImage = slideWidth * 0.02; // Margine del 2%
        const imageWidthBottom = (slideWidth - 3 * marginBottomImage) / 2; // 2 immagini in fila
    
        // **Posizioni X**
        const positionsXTop = [
            0, // Sinistra
            margin + imageWidthTop, // Fronte
            2 * margin + 2 * imageWidthTop, // Destra
        ];
    
        const positionsXBottom = [
            marginBottomImage, // Mandibolare
            (marginBottomImage * 2) + imageWidthBottom, // Mascellare
        ];
    
        presentations.forEach((presentation) => {
            const slide = pptx.addSlide();
            const types = ['Sinistra', 'Fronte', 'Destra', 'Mandibolare', 'Mascellare'];
    
            const imagesBase64 = types.map((item) => {
                const image = presentation.images.find((image) => image.type === item);
                return image ? `data:image/jpeg;base64,${image.base64}` : null;
            });
    
            // **Aggiunta immagini nella riga superiore**
            slide.addImage({ data: imagesBase64[0], x: positionsXTop[0], y: topMargin, w: imageWidthTop, h: imageHeightTop });
            slide.addImage({ data: imagesBase64[1], x: positionsXTop[1], y: topMargin, w: imageWidthTop, h: imageHeightTop });
            slide.addImage({ data: imagesBase64[2], x: positionsXTop[2], y: topMargin, w: imageWidthTop, h: imageHeightTop });
    
            // **Aggiunta immagini nella riga inferiore**
            slide.addImage({ data: imagesBase64[3], x: positionsXBottom[0], y: imageHeightTop + 0.5, w: imageWidthBottom, h: imageHeightBottom });
            slide.addImage({ data: imagesBase64[4], x: positionsXBottom[1], y: imageHeightTop + 0.5, w: imageWidthBottom, h: imageHeightBottom });
        });
    
        // Salva il file PowerPoint
        pptx.writeFile({ fileName: "Caso_Clinico_Tutte_Slides.pptx" });
    };

    {/*
        fetch('https://sftp.centrodelsorriso.tribusinformatica.it/fotografie/compressioni/Lorenzo_Tozzi/2025_01_22/1737622781013_ceuglr48_2023_10_19__6.jpg', {
            method: 'GET',
            credentials: 'include'  // Include i cookie o il token di sessione
        })
        .then(response => response.blob())
        .then(blob => {
            console.log(blob, 'KKKKKKKKKKKKKKKKKKKK')
            let imgURL = URL.createObjectURL(blob);
            document.getElementById('image').src = imgURL;
        })
        .catch(error => console.error('Errore nel caricamento:', error));
    */}

    const generatePresentation3 = () => {
        console.log('çççççççççç')
        const pptx = new PptxGenJS();

        try {
            // Itera attraverso tutte le presentazioni e crea una slide per ognuna
            presentationsCopy.forEach((presentation, index) => {
                const slide = pptx.addSlide();
        
                // Aggiungi le immagini della presentazione (qui stiamo usando l'immagine come esempio)
                // Modifica secondo i dati specifici di ogni "presentation"

                const types = [ 'Sinistra', 'Fronte',  'Destra', 'Mandibolare', 'Mascellare'];

                
                const imagesBase64 = types.map((item)=>{
                const image = presentation.images.find((image)=> image.type === item)
                const imageBase64 = `data:image/jpeg;base64,${image.base64.toString("base64")}`;
                return imageBase64
                })
                slide.addImage({ data: imagesBase64[0], x: 0, y: 0.2, w: 3.27, h: 2, sizing: {type:"contain"}});
                // Aggiungi 5 immagini segnaposto alla slide
                slide.addImage({ data: imagesBase64[1], x: 3.36, y: 0.2, w: 3.27, h: 2, sizing: {type:"contain"}});
                slide.addImage({ data: imagesBase64[2], x: 6.73, y: 0.2, w: 3.27, h: 2, sizing: {type:"contain"}});
                slide.addImage({ data: imagesBase64[3], x: 0.84, y: 2.38, w: 3.63, h: 3.06, sizing: {type:"contain"} });
                slide.addImage({ data: imagesBase64[4], x: 5.5, y: 2.38, w: 3.63, h: 3.06, sizing: {type:"contain"} });

                {/*
                    // Aggiungi 5 immagini segnaposto alla slide
                    slide.addImage({ path: "https://sftp.centrodelsorriso.tribusinformatica.it/fotografie//compressioni/Mario_Rossi/2025_02_13/1739551434616_ar1wb9gb_2023_10_19__3.jpg", x: 0, y: 0.2, w: 3.27, h: 2, sizing: {type:"contain"}});
                    slide.addImage({ path: "https://sftp.centrodelsorriso.tribusinformatica.it/fotografie//compressioni/Mario_Rossi/2025_02_13/1739551434616_ar1wb9gb_2023_10_19__3.jpg", x: 3.36, y: 0.2, w: 3.27, h: 2, sizing: {type:"contain"}});
                    slide.addImage({ path: "https://sftp.centrodelsorriso.tribusinformatica.it/fotografie//compressioni/Mario_Rossi/2025_02_13/1739551434616_ar1wb9gb_2023_10_19__3.jpg", x: 6.73, y: 0.2, w: 3.27, h: 2, sizing: {type:"contain"}});
                    slide.addImage({ path: "https://sftp.centrodelsorriso.tribusinformatica.it/fotografie//compressioni/Mario_Rossi/2025_02_13/1739551434616_ar1wb9gb_2023_10_19__3.jpg", x: 0.84, y: 2.38, w: 3.63, h: 3.06, sizing: {type:"contain"} });
                    slide.addImage({ path: "https://sftp.centrodelsorriso.tribusinformatica.it/fotografie//compressioni/Mario_Rossi/2025_02_13/1739551434616_ar1wb9gb_2023_10_19__3.jpg", x: 5.5, y: 2.38, w: 3.63, h: 3.06, sizing: {type:"contain"} });
                */}
            });
        } catch (error) {
            console.log(error);
        }
    

    
        // Salva il file PowerPoint con tutte le presentazioni
        pptx.writeFile({ fileName: "Caso_Clinico_Tutte_Slides.pptx" });
    };

    const generatePresentationPdf = () => {
        const pdf = new jsPDF("landscape"); // Orientamento orizzontale
    
        presentationsCopy.forEach((presentation, index) => {
            if (index !== 0) pdf.addPage(); // Aggiungi una nuova pagina se non è la prima
            
            const types = ["Sinistra", "Fronte", "Destra", "Mandibolare", "Mascellare"];
            const pageWidth = pdf.internal.pageSize.getWidth(); // Larghezza della pagina
            const pageHeight = pdf.internal.pageSize.getHeight(); // Altezza della pagina
    
            const imageWidth = 60; // Larghezza standard per immagini superiori
            const imageHeight = 40;
            const lowerImageWidth = 70; // Larghezza per immagini inferiori
            const lowerImageHeight = 50;
    
            // Dati del cliente e data per il titolo
            const clientName = `${Selectedpatient.name} ${Selectedpatient.surname}`;
            const date = moment(presentation.groupe).format("DD/MM/YYYY");
    
            // Centra il nome e la data in alto
            const headerSpacing = 15; // Spazio tra il titolo e le immagini
            pdf.setFontSize(14);
            pdf.text(clientName, pageWidth / 2, 10, { align: 'center' });
            pdf.setFontSize(12);
            pdf.text(date, pageWidth / 2, 20, { align: 'center' });
    
            const imagesBase64 = types.map((item) => {
                const image = presentation.images.find((image) => image.type === item);
                return `data:image/jpeg;base64,${image.base64.toString("base64")}`;
            });
    
            // Centriamo le immagini in base alla larghezza della pagina
            const spacing = 10;
            const totalWidth = imageWidth * 3 + spacing * 2; // Larghezza totale delle tre immagini superiori
            const startX = (pageWidth - totalWidth) / 2; // Calcolo per centrare le immagini in orizzontale
    
            // Calcolo della posizione verticale per centrare le immagini (considerando anche il testo sopra)
            const totalImageHeight = imageHeight + lowerImageHeight + spacing; // Spazio totale richiesto per le immagini
            const startY = (pageHeight - totalImageHeight - headerSpacing) / 2; // Calcolo per centrare le immagini in verticale
    
            // Posizioniamo le immagini superiori (Sinistra, Fronte, Destra)
            pdf.addImage(imagesBase64[0], "JPEG", startX, startY, imageWidth, imageHeight);
            pdf.addImage(imagesBase64[1], "JPEG", startX + imageWidth + spacing, startY, imageWidth, imageHeight);
            pdf.addImage(imagesBase64[2], "JPEG", startX + (imageWidth + spacing) * 2, startY, imageWidth, imageHeight);
    
            // Posizioniamo le immagini inferiori (Mandibolare, Mascellare)
            const lowerStartX = (pageWidth - lowerImageWidth * 2 - spacing) / 2; // Calcolo per centrare le immagini in basso
            pdf.addImage(imagesBase64[3], "JPEG", lowerStartX, startY + imageHeight + spacing, lowerImageWidth, lowerImageHeight);
            pdf.addImage(imagesBase64[4], "JPEG", lowerStartX + lowerImageWidth + spacing, startY + imageHeight + spacing, lowerImageWidth, lowerImageHeight);
        });
    
        pdf.save("Caso_Clinico_Tutte_Slides.pdf");
    };
    

    // Funzione per ricavare tutti studio
    const getAllStudios = async () => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/studio/fetch_all`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: {
                    usernameGet: getDecryptedCookie(
                        process.env.REACT_APP_COOKIE_USERNAME,
                    ),
                },
            };

            const status_token = await checkToken();
    
            if (status_token) {

                // Effettua la richiesta con axios
                const response = await axios.request(config);

                // Controllo la risposta
                if (response.data.status === true) {
                    // Setta lo state con i pazienti
                    setStudios(response.data.studios);
                } else {
                    // Gestisci il caso in cui la risposta abbia status false
                    console.log("Errore nella risposta:", response.data.message);
                    // Potresti anche gestire il caso di errore impostando un messaggio di stato o un alert
                }
            } else {
                handleLogout();
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell"errore, ad esempio aggiornando lo stato per mostrare un messaggio all"utente
        }
    };

    const getAllPresentation = async (Selectedpatient) => {
        setPresentationsLoading(true);
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/presentation/fetch`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                params: { 
                    patient_id: Selectedpatient._id ? Selectedpatient._id : '' , 
                }
            };

            const status_token = await checkToken();
    
            if (status_token) {

                // Effettua la richiesta con axios
                const response = await axios.request(config);

                // Controllo la risposta
                if (response.data.status === true) {
                    // Setta lo state con i pazienti
                    setPresentations(response.data.presentations);
                    Addbuss64(response.data.presentations);
                    setPresentationsLoading(false);
                } else {
                    // Gestisci il caso in cui la risposta abbia status false
                    console.log("Errore nella risposta:", response.data.message);
                    // Potresti anche gestire il caso di errore impostando un messaggio di stato o un alert
                }
            } else {
                handleLogout();
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell"errore, ad esempio aggiornando lo stato per mostrare un messaggio all"utente
        }
    };

    const Addbuss64 = async (presentations) => {
        setPresentationsLoadingcopy(true);
        let item = []
         
        if(presentations.length > 0){

            for (const presentation of presentations) {
                try {
                    // Ottieni il token in modo asincrono
                    const token = await getAuthToken();
                    const status_token = await checkToken();
        
                    // Configura la richiesta
                    let config = {
                        method: "post",
                        maxBodyLength: Infinity,
                        url: `${process.env.REACT_APP_URL_API}/presentation/addImage`,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`, // Usa il token ottenuto
                        },
                        data: { 
                            images: presentation.images, 
                        }
                    };
        
                    if (status_token) {
                        // Effettua la richiesta con axios
                        const response = await axios.request(config);
                        // Controllo la risposta
                        if (response.data.status === true) {
                            // Setta lo state con i pazienti
                            item.push({...presentation, images: response.data.UpdatesImages})
                            
                        } else {
                            // Gestisci il caso in cui la risposta abbia status false
                            console.log("Errore nella risposta:", response.data.message);
                            // Potresti anche gestire il caso di errore impostando un messaggio di stato o un alert
                        }
                    } else {
                        handleLogout();
                    }
                } catch (error) {
                    console.log("Errore nella richiesta:", error);
                    // Gestione dell"errore, ad esempio aggiornando lo stato per mostrare un messaggio all"utente
                }
    
            }
        }

        setPresentationsCopy(item);
        setPresentationsLoadingcopy(false);

    };

    //Funzione per recuperare tutta la lista degli utenti
    const getAllConnection = async () => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/patients/fetch_filtered`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                params: { // 👈 Params deve stare qui
                    home_office: selectedStudio,
                    search: inputSearch
                }
            };

            const status_token = await checkToken();
    
            if (status_token) {

                // Effettua la richiesta con axios
                const response = await axios.request(config);

                // Controllo la risposta
                if (response.data.status === true) {
                    // Setta lo state con i pazienti
                    setPatients(response.data.patients.map((item) => ({
                        ...item,
                        show: false
                    })));
                    const selectedpatientPlaceHoalder =  response.data.patients && response.data.patients.length > 0 ? response.data.patients[0] : {}
                    setSelectedpatient(selectedpatientPlaceHoalder);

                    // Set loading
                    setLoadingPatients(true);
                } else {
                    // Gestisci il caso in cui la risposta abbia status false
                    console.log("Errore nella risposta:", response.data.message);
                    // Potresti anche gestire il caso di errore impostando un messaggio di stato o un alert
                }
            } else {
                handleLogout();
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell"errore, ad esempio aggiornando lo stato per mostrare un messaggio all"utente
        }
    };

    //Funzione per recuperare tutta la lista degli utenti
    const getSettings = async () => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/settings/get-setup.by-user`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: {
                    usernameGet: getDecryptedCookie(
                        process.env.REACT_APP_COOKIE_USERNAME,
                    ),
                },
            };

            const status_token = await checkToken();
    
            if (status_token) {

                // Effettua la richiesta con axios
                const response = await axios.request(config);

                // Controllo la risposta
                if (response.data.status) {
                    setSettings(response.data.data)
                } else {
                    // Gestisci il caso in cui la risposta abbia status false
                    console.log("Errore nella risposta:", response.data.message);
                    // Potresti anche gestire il caso di errore impostando un messaggio di stato o un alert
                }
            } else {
                handleLogout();
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell"errore, ad esempio aggiornando lo stato per mostrare un messaggio all"utente
        }
    };
    
    useEffect(() => {
        getAllStudios();
        getAllConnection();
        getSettings();
    }, [selectedStudio,inputSearch]);

    //Funzione per Generare l"immagine
    function generateInitialsImage(name, surname) {
        const initials = (name[0] || "") + (surname[0] || "");
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // Imposta le dimensioni del canvas
        const size = 80; // Dimensione dell"immagine
        canvas.width = size;
        canvas.height = size;

        // Imposta il colore di sfondo
        context.fillStyle = "#5930B6"; // Colore di sfondo (puoi personalizzarlo)
        context.fillRect(0, 0, size, size);

        // Imposta le proprietà del testo
        context.fillStyle = "#fff"; // Colore del testo
        context.font = "36px Urbanist, sans-serif"; // Font e dimensione del testo

        context.textAlign = "center";
        context.textBaseline = "middle";

        // Disegna le iniziali al centro del canvas
        context.fillText(initials, size / 2, size / 2);

        // Ritorna l"URL dell"immagine generata
        return canvas.toDataURL("image/png");
    };

    // Genera l"immagine con le iniziali dell"utente
    const initialsImageUrl = generateInitialsImage(nome, cognome);

    const GetAnno = () => {
        return new Date().getFullYear();
    };

    function getStudioNameById(id) {
        // Trova l'oggetto con l'_id corrispondente
        const foundObject = studios.find(item => item._id === id);
        // Restituisci il nome se trovato, altrimenti null
        return foundObject ? foundObject.name : null;
    };

    function GroupsList( groups ) {
        return (
            <div className="overflow-x-auto bg-gray-50 p-4">
                {presentationsLoading ? (
                    <div className="w-full flex flex-col justify-center items-center">
                        <div className="w-full flex flex-row justify-center items-center py-4">
                            <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
                        </div>
                    </div>
                ) : (
                    <ul className="flex space-x-6 ">
                        {groups.map((group) => {
                            const presentazionExist = presentations.find((item) =>
                                moment(item.groupe).isSame(moment(group.title), 'day')
                            );
    
                            return (
                                <li key={group.id} className="w-60 rounded-lg bg-white p-2 text-center shadow-md transition-transform hover:scale-105">
                                    <h3 className="text-sm font-semibold text-gray-700">
                                        {moment(group.title).format("DD/MM/YYYY")}
                                    </h3>
                                    <p className="mt-1 text-xs text-gray-500">
                                        Seleziona immagini per la presentazione del {moment(group.title).format("DD/MM/YYYY")}
                                    </p>
                                    
                                    <button
                                        className={`mt-2 w-full rounded-md px-3 py-1.5 text-white text-sm transition-all duration-300 
                                            ${presentazionExist ? "bg-yellow-600 hover:bg-yellow-500" : "bg-indigo-600 hover:bg-indigo-500"}
                                        `}
                                        onClick={() => {
                                            setModaldata(group.items);
                                            setGroup(group.title);
                                            setopen(true); }}
                                    >
                                        {presentazionExist ? "Modifica" : "Crea"}
                                    </button>
                                </li>
                            );
                        })}
                            <li className="flex items-center justify-center gap-2 rounded-lg bg-white p-2 shadow-md">

                            {presentationsLoadingCopy ? 
                                (
                                    <div className="w-full flex flex-col justify-center items-center w-40">
                                        <div className="w-full flex flex-row justify-center items-center py-4">
                                            <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
                                        </div>
                                    </div>
                                ) 

                                :

                                (<>   
                                    {presentationsCopy.length > 0 ? (
                                    <>
                                        <button
                                        className="flex items-center justify-center w-12 h-12 rounded-lg bg-yellow-500 text-yellow-700 transition-all duration-300 hover:bg-yellow-400 hover:text-white"
                                        onClick={generatePresentation3}
                                        >
                                        <PowerPointIcon className="w-6 h-6" />
                                        </button>
                                        <button
                                        className="flex items-center justify-center w-12 h-12 rounded-lg bg-yellow-500 text-yellow-700 transition-all duration-300 hover:bg-yellow-400 hover:text-white"
                                        onClick={generatePresentationPdf}
                                        >
                                        <PdfIcon className="w-6 h-6" />
                                        </button>
                                    </>
                                    ) : (
                                        <div className="flex flex-col items-center justify-center rounded-md bg-yellow-50 p-4 ">
                                            <ExclamationTriangleIcon aria-hidden="true" className="size-8 text-yellow-400" />
                                            <h3 className="mt-2 text-sm font-medium text-yellow-800 text-center">
                                                Nessuna presentazione trovata
                                            </h3>
                                        </div>

                                    )}
                                </>)
                            }

                            </li>

                    </ul>
                )}
            </div>
        );
    }

    function StudioSelect(studios) {
    
        return (
            <div className="relative w-auto">
                <select
                    id="location"
                    name="location"
                    value={selectedStudio}
                    onChange={(e) => setSelectedStudio(e.target.value)}
                    className="w-auto bg-white py-1.5 pl-10 pr-8 rounded-lg text-sm focus:outline-none 
                               focus:ring-1 focus:ring-indigo-500 shadow-sm transition border border-gray-300 
                               appearance-none" // Nasconde la freccia predefinita
                >
                    <option value="" className="text-gray-400">
                        Nessuno studio selezionato
                    </option>
                    {studios.map((studio) => (
                        <option key={studio._id} value={studio._id} className="text-gray-900 text-sm bg-white hover:bg-gray-100">
                            {studio.name}
                        </option>
                    ))}
                </select>
    
                {/* Icona sulla sinistra */}
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M5.3 8.7a1 1 0 011.4-1.4l5.3 5.3a1 1 0 01-1.4 1.4L5.3 8.7z"
                            clipRule="evenodd"
                        />
                    </svg>
                </span>
    
                {/* Icona della freccia sulla destra */}
                <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M10 12a1 1 0 01-.7-.3l-4-4a1 1 0 111.4-1.4L10 9.6l3.3-3.3a1 1 0 111.4 1.4l-4 4a1 1 0 01-.7.3z"
                            clipRule="evenodd"
                        />
                    </svg>
                </span>
            </div>
        );
    }

    const groupByCreationDate = (array) => {
        return array.reduce((acc, item) => {
          const date = item.creation_date.split("T")[0]; // Extract the date (YYYY-MM-DD)
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(item);
          return acc;
        }, {});
    };
    
    useEffect(() => {
        if (Selectedpatient && Object.keys(Selectedpatient).length > 0) {
            
            let groupedData = [];
            const dateGroup = groupByCreationDate(Selectedpatient.src_images);
            const url = settings.type === "SFTP" 
                ? `${settings.url_pubblico}/${settings.folderName}/` 
                : settings.url_pubblico;
            
            const types = [ 'Sinistra', 'Fronte',  'Destra', 'Mandibolare', 'Mascellare'];
            
            let id = 0;
    
            for (const key in dateGroup) {
                id++;
                
                const items = types.map((type) => {
                    const Images = dateGroup[key].filter(image => image.type === type);
                    return {
                        url: url,
                        type: type,
                        indexToShow: 0,
                        data: Images,
                        get urlToShow() {
                            return `${this.url}/compressioni/${this.data[this.indexToShow]?.path || ""}`;
                        },
                        get idShowen() {
                            return this.data[this.indexToShow]?._id;
                        },
                        showNextImage() {
                            setModaldata(prev => prev.map(item => {
                                if (item.type === this.type) {
                                    if (item.indexToShow < item.data.length - 1) {
                                        return {
                                            ...item, 
                                            indexToShow: item.indexToShow + 1, 
                                            urlToShow: `${this.url}/compressioni/${this.data[item.indexToShow + 1]?.path || ""}`,
                                            idShowen: this.data[item.indexToShow + 1]?._id
                                        };
                                    } else {
                                        return item;
                                    }
                                } else {
                                    return item;
                                }
                            }));
                        },
                        showPreviousImage() {
                            setModaldata(prev => prev.map(item => {
                                if (item.type === this.type) {
                                    if (item.indexToShow > 0) {
                                        return {
                                            ...item, 
                                            indexToShow: item.indexToShow - 1, 
                                            urlToShow: `${this.url}/compressioni/${this.data[item.indexToShow - 1]?.path || ""}`,
                                            idShowen: this.data[item.indexToShow - 1]?._id
                                        };
                                    } else {
                                        return item;
                                    }
                                } else {
                                    return item;
                                }
                            }));
                        }
                    };
                });
    
                groupedData.push({
                    id: id,
                    title: key,
                    items: items,
                });
            }
            
            setGroups(groupedData);
            
        }
    }, [Selectedpatient, settings]);
    
    
    return (
        <>
            <div className="flex flex-col min-h-screen bg-gray-100">
                {/* Menù navigazione */}
                <Disclosure as="nav" className="bg-white shadow-sm">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="block h-8 w-auto lg:hidden"
                                    />
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="hidden h-8 w-auto lg:block"
                                    />
                                </div>
                                <MenuNavigazioneDesktop
                                    ruolo={ruolo}
                                    navigate={navigate}
                                />
                            </div>

                            <div className="hidden xl:flex sm:ml-6 sm:items-center">
                                {/* Profile dropdown */}
                                <MenuDesktop
                                    nome={nome}
                                    cognome={cognome}
                                    ruolo={ruolo}
                                    email={email}
                                    initialsImageUrl={initialsImageUrl}
                                    handleLogout={handleLogout}
                                    handleProfilo={handleProfilo}
                                />
                            </div>

                            {/* Mobile Section */}
                            <div className="-mr-2 flex items-center xl:hidden">
                                {/* Mobile menu button */}
                                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    <Bars3Icon
                                        aria-hidden="true"
                                        className="block h-6 w-6 group-data-[open]:hidden"
                                    />
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="hidden h-6 w-6 group-data-[open]:block"
                                    />
                                </DisclosureButton>
                            </div>
                        </div>
                    </div>

                    {/* Mobile  */}
                    <DisclosurePanel className="xl:hidden">
                        <MenuNavigazioneMobile
                            ruolo={ruolo}
                            navigate={navigate}
                        />
                        <MenuMobile
                            nome={nome}
                            cognome={cognome}
                            ruolo={ruolo}
                            email={email}
                            initialsImageUrl={initialsImageUrl}
                            handleLogout={handleLogout}
                            handleProfilo={handleProfilo}
                        />
                    </DisclosurePanel>
                </Disclosure>

                {/* Alert per mobile */}
                <div className="grow h-auto block xl:hidden py-10">
                    <header>
                        <div className="lg:mx-20 md:mx-10 mx-6 max-w-full px-4 sm:px-6 lg:px-8 bg-white shadow rounded-lg p-8">
                            <div className="md:grid md:grid-cols-2 flex justify-center md:justify-start items-center">
                                <div>
                                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 text-center md:text-left">
                                        Presentazione
                                    </h1>
                                    <h3 className="text-base font-bold leading-tight tracking-tight text-gray-900 text-center md:text-left">
                                        Informazioni
                                    </h3>
                                    <p className="mt-1 mb-2 max-w-2xl text-sm leading-6 text-gray-500 text-center md:text-left">
                                        Sezione dedicata alla gestione dei pazienti.
                                    </p>
                                </div>
                            </div>

                            {/* Alert */}
                            <div className="rounded-md bg-yellow-50 p-4 mt-6">
                                <div className="flex flex-col justify-center items-center">
                                    <div className="shrink-0">
                                        <ExclamationTriangleIcon aria-hidden="true" className="size-5 text-yellow-400" />
                                    </div>
                                    <div className="flex flex-col justify-center items-center">
                                        <h3 className="text-sm font-medium text-yellow-800">Attenzione</h3>
                                        <p className="mt-2 text-sm text-center text-yellow-700">
                                            Non è possibile accedere a questa sezione da dispositivi mobile.
                                        </p>
                                        <p className="mt-2 text-sm text-center text-yellow-700">
                                            Per effettuare delle modifiche alla sezione "Presentazione" accedere da un dispositivo desktop.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>

                {/* Gallery per desktop */}
                <div className="grow h-auto hidden xl:block">
                    <header>
                        <div className="mx-5 xl:mx-20 max-w-full bg-white shadow sm:rounded-lg my-10 overflow-hidden h-full">
                            <div className="grid grid-cols-2 items-center pt-5 pb-4 px-4 sm:px-6 lg:px-8">
                                <div>
                                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                                        Presentazione
                                    </h1>
                                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                                        Informazioni
                                    </h3>
                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                                        Sezione dedicata alla gestione degli pazienti.
                                    </p>
                                </div>
                            </div>

                            <div className="flex" >

                                {/* Sidebar per desktop */}
                                <div className="flex w-64 flex-col  text-white  ">
                                    <div className="text-lg h-full font-bold p-4 bg-indigo-900">Lista Pazienti</div>
                                </div>

                                {/* Contenuto principale */}
                                <div className="flex-1 bg-gray-100">
                                    <div className="flex justify-between items-center p-4 ">
                                            {/*<h2 className="text-lg leading-6 font-medium text-gray-900">
                                                Gestione Pazienti
                                            </h2>*/}
                                            

                                            {/*
                                                <PowerPoint />     
                                            */}    
                                             
                                                
                                        <div className="flex flex-row items-center ml-auto">
                                            {StudioSelect(studios)}

                                            <div className="relative mr-6">
                                                <input
                                                    type="text"
                                                    className={`ml-2 border bg-white py-1.5 pl-8 pr-4 rounded-lg text-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 shadow-sm transition`}
                                                    value={inputSearch}
                                                    placeholder="Cerca"
                                                    onChange={(e)=>{setInputSearch(e.target.value)}}
                                                />
                                                <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-4 w-4"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M12.9 14.32a8 8 0 111.41-1.41l4.3 4.29a1 1 0 01-1.42 1.42l-4.29-4.3zM14 8a6 6 0 11-12 0 6 6 0 0112 0z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="">
                                <div>
                                    <div className="px-4 sm:px-0"></div>
                                    <div className="w-full">
                                        {loadingAlertGreen ? (
                                            <AlertGreen testo={textGreenAlert} />
                                        ) : null}
                                    </div>
                                    {loadingPatients  ?
                                        <Galleria patients={patients} settings={settings} Selectedpatient={Selectedpatient} setSelectedpatient={setSelectedpatient} getStudioNameById={getStudioNameById} getAllConnection={getAllConnection} getAllPresentation={getAllPresentation} groups={groups} GroupsList={GroupsList} presentationsLoading={presentationsLoading} presentations={presentations}  />
                                    : 
                                        <div className="h-[60vh]">
                                            <div className="flex justify-center items-center h-full w-full">
                                                <div className="w-full flex flex-row justify-center items-center py-4">
                                                    <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </header>
                </div>

                {open ?
                    <PresentationModale isOpen={open} onClose={setopen} ModalData={ModalData} group={group}  Selectedpatient={Selectedpatient} getAllPresentation={getAllPresentation} />
                : null}



                {/* Footer */}
                <footer className="bg-white">
                    <div className="mx-auto px-6 sm:py-3 md:py-3 xl:py-5 items-center">
                        <div className="mt-4 md:order-1 md:mt-0">
                            <p className="text-center text-xs leading-5 text-gray-500">
                                {GetAnno()} © Centro del Sorriso, sviluppato
                                con il ❤️ da Tribus Informatica Srl
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Pazienti;