import React, { useState, useEffect, useRef } from "react";
import { MuuriComponent, useGrid, useRefresh, useDrag , useDraggable} from "muuri-react";
import { generateItems, useSend, getDimensions, getOptions } from "./utils";
import { Demo, Main, Dashboard } from "./components";
import "./style.css";
import getAuthToken from "../../features/auth/axiosSetup";
import axios from "axios";
import { UsersIcon } from "@heroicons/react/24/outline";
import Drawer from "../componets/Drawer";
import moment from "moment"
import ModalImage from "../componets/ModalImagesApruvedPresentation";


const App = ({ Selectedpatient, settings , patients , getAllConnection , presentations }) => {

  const [data, setData] = useState([]);
  const [patient, setPatient] = useState({});
  const [onSendData, setOnSendData] = useState({});
  const [startDrag, setStartDrag] = useState(false);
  const [imageToMouve, setimageToMouve] = useState(false);
  const [open, setOpen] = useState(false);
  const [isOnSeand, setIsOnSeand] = useState(false);
  //sate per modificare il type e delle immagine
  const [src, setSrc] = useState(""); 
  const Tryurl = settings.type === "NFS/CIFS/SMB" ? `${settings.url_pubblico}/compressioni/`  :  `${settings.url_pubblico}/${settings.folderName}/compressioni/`
  const [url, setUrl] = useState();  
  const [totApprovati, setTotApprovati] = useState(0);
  const [totNonApprovati, setTotNonApprovati] = useState(0);

  const [imageType, setImageType] = useState('');
  const [patientImages, setPatientImages] = useState([]);

  const [checkboxState, setCheckboxState] = useState("toBeEvaluated")
  const [openModalImage, setOpenModalImage] = useState(false);
  const [finalloading, setFinalLoading] = useState(false);  

  // Group images by creation date
  const groupByCreationDate = (array) => {
    return array.reduce((acc, item) => {
      const date = item.creation_date.split("T")[0]; // Extract the date (YYYY-MM-DD)
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };
  // On initial load, group data
  useEffect(() => {
    setPatient(Selectedpatient)
    let groupedData = [];
    const dateGroup = groupByCreationDate(Selectedpatient.src_images);

    let id = 0

    for (const key in dateGroup) {
      id++


      const items = dateGroup[key].map((item) => ({
        id: item._id,
        content: item.path,
        group: key, // Store the group (date)
        order: item.order,
      })).sort((a, b) => a.order - b.order);
      groupedData.push({
        id: id,
        title: key,
        items: items,
      });
    }

    setData(groupedData);
  }, [Selectedpatient]);

  useEffect(() => {
    setPatient(Selectedpatient)
    let groupedData = [];
    const dateGroup = groupByCreationDate(Selectedpatient.src_images);

    let id = 0

    for (const key in dateGroup) {
      id++


      const items = dateGroup[key].map((item) => ({
        id: item._id,
        content: item.path,
        group: key, // Store the group (date)
        order: item.order,
      })).sort((a, b) => a.order - b.order);
      groupedData.push({
        id: id,
        title: key,
        items: items,
      });
    }

    setData(groupedData);
  }, [Selectedpatient]);

  useEffect(() => {
    if(onSendData?.status){
      const {updatedItems, key, fromId, toId , status} = onSendData;
      // Calcolare le nuove date formattate
      let dateFrom = moment(updatedItems.find(item => item.id === fromId).title).format("YYYY_MM_DD");
      let dateTo = moment(updatedItems.find(item => item.id === toId).title).format("YYYY_MM_DD");

      const update_fieldsFromIds = updatedItems.find(field => fromId === field.id).items.map(oggetto => oggetto.id);
      const update_fieldsTOIdis = updatedItems.find(field => toId === field.id).items.map(oggetto => oggetto.id);
      SendeAndOrderImg(key, fromId, toId, dateFrom , dateTo , update_fieldsFromIds , update_fieldsTOIdis)

    }
  }, [onSendData]);

  const scrollContainerRef = useRef(null);

  const handleDragStart = (item, event) => {

    const destinationGrid = item.getGrid();
    const prova = destinationGrid.getItems()

    // Puoi aggiungere logica per evidenziare o modificare lo stato di un elemento
  };
  
  const handleDragMove = (item, event) => {

    // Puoi aggiornare la posizione o eseguire calcoli mentre l'item viene trascinato
  };
  
  const handleDragEnd = (item, event, groupId) => {
    const draggedElement = item.getElement(); // Elemento DOM spostato
    const destinationGrid = item.getGrid(); // Griglia di destinazione
    const destinationGroupId = groupId; // ID del gruppo di destinazione
    const prova = destinationGrid.getItems()

    // Ottieni il nuovo ordine degli elementi nella griglia di destinazione
    const orderedItems = destinationGrid.getItems().map((gridItem) => {
      const element = gridItem.getElement();
      const id = element.id; // Ottieni l'ID dal DOM
      return id;
    });
  
    // Aggiorna i dati per il gruppo modificato
    setData((prevData) => {
      // Trova il gruppo che deve essere aggiornato
      return prevData.map((group) => {
        if (group.id === destinationGroupId) {
          // Aggiorna gli elementi del gruppo con il nuovo ordine
          const reorderedItems = orderedItems.map((id) => {
            // Trova il contenuto originale corrispondente all'ID
            return group.items.find((item) => item.id === id);
          });

            if(!isOnSeand){
              OrgerImages(reorderedItems)
            }


          return {
            ...group,
            items: reorderedItems,
          };
        }
        return group;
      });
    });

    setStartDrag(false);
    setIsOnSeand(false);
  
  };

  const OrgerImages = async (update_fields) => {

    let new_src_images = Selectedpatient.src_images.map(image => {
      const updatedField = update_fields.find(field => image._id.toString() === field.id);
      if (updatedField) {
          //debugItem.push(updatedField)
          return {
              ...image,
              order: update_fields.indexOf(updatedField) + 1, // Calcola l'ordine basandoti sull'indice
          };
      }

      return image; // Restituisci l'immagine originale se non deve essere aggiornata
  });

    try {
      // Ottieni il token in modo asincrono
      const token = await getAuthToken();

      // Configura la richiesta
      let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_URL_API}/patients/order-img`,
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${token}`, // Usa il token ottenuto
          },
          data: { 
            patient_id: Selectedpatient._id,
            new_src_images: new_src_images
          }
      };

      // Effettua la richiesta con axios
      const response = await axios.request(config);

      //Controllo la risposta e conseguneza setto gli state
      if (response.data.success) {
          //Nessuna configurazione trovata stampo badge e diabilito configurazione
          getAllConnection();

      }
  } catch (error) {
      console.log("Errore nella richiesta:", error);
  }

  }

  const SendeAndOrderImg = async ( key, fromId, toId , dateFrom , dateTo , update_fieldsFromIds , update_fieldsTOIdis)=>{
    setIsOnSeand(true);
    try {
      // Ottieni il token in modo asincrono
      const token = await getAuthToken();

      // Configura la richiesta
      let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_URL_API}/patients/send-order-img`,
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${token}`, // Usa il token ottenuto
          },
          data: { 
            patient_id: patient._id,
            key,  dateFrom , dateTo 
          }
      };

      // Effettua la richiesta con axios
      const response = await axios.request(config);

      //Controllo la risposta e conseguneza setto gli state
      if (response.data.success) {
          //Nessuna configurazione trovata stampo badge e diabilito configurazione
          setOnSendData({});

          getAllConnection();

          {/*
            const DatatoREOrder = data.filter((item)=>item.id === fromId || item.id === toId);
  
            DatatoREOrder.forEach((data)=>{
              console.log(data.items)
              OrgerImages(data.items);
            })
          */}

      }
    } catch (error) {
        console.log("Errore nella richiesta:", error);
    }
  }

  const headelOpenImageModale = async (groupId)=>{

     const itemIds = data.find((item)=>item.id === groupId).items.map((item=>item.id));
     const Images = patient.src_images.filter((image)=> itemIds.includes(image._id.toString()));
       const Tryurl = settings.type === "NFS/CIFS/SMB" ? `${settings.url_pubblico}/compressioni`  :  `${settings.url_pubblico}/${settings.folderName}/compressioni`
     setUrl(Tryurl);
     setSrc(`${Tryurl}/${Images[0].path}`);
     setImageType(Images[0].type);
     setCheckboxState(Images[0].stato);

     const { totApprovati, totNonApprovati } = Images.reduce((acc, e) => {
      if (e.stato === "approve") {
          acc.totApprovati++;
      } else if (e.stato === "notApprove") {
          acc.totNonApprovati++;
      }
      return acc;
  }, { totApprovati: 0, totNonApprovati: 0 });
  
  setTotApprovati(totApprovati);
  setTotNonApprovati(totNonApprovati);
  
     setTotNonApprovati(totNonApprovati);

     setPatientImages(Images);
     setOpenModalImage(true)


  }

  const UpdateImageSate = async (patientId, data)=>{
    setFinalLoading(true);
    try {
        // Ottieni il token in modo asincrono
        const token = await getAuthToken();

            const response = await axios.post(
                `${process.env.REACT_APP_URL_API}/patients/update-imgState`,
                {
                    patient_id: patientId,
                    update_fields: data,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );  

            
            if (response.data.success) {
                setOpenModalImage(false);
                setFinalLoading(false);
                getAllConnection();

            } else {
                console.log("Errore durante la chiamata");
                setFinalLoading(false);
            }

    } catch (error) {
        console.log(error) 
        setFinalLoading(false);
    }
  }
  
  const onSend = useSend(setData , setOnSendData);

  // Effect for mouse scroll behavior
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleMouseMove = (event) => {
      if (!scrollContainer) return;

      const { top, bottom, left, right } = scrollContainer.getBoundingClientRect();
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      const baseScrollSpeed = 10;
      const maxScrollSpeed = 50;
      const minDistance = 20;

      const calculateScrollSpeed = (distance) => {
        return Math.min(maxScrollSpeed, baseScrollSpeed + (minDistance - distance) * 0.75);
      };

      if (mouseY <= top + minDistance) {
        const speed = calculateScrollSpeed(top + minDistance - mouseY);
        scrollContainer.scrollTop -= speed;
      } else if (mouseY >= bottom - minDistance) {
        const speed = calculateScrollSpeed(mouseY - (bottom - minDistance));
        scrollContainer.scrollTop += speed;
      }

      if (mouseX <= left + minDistance) {
        const speed = calculateScrollSpeed(left + minDistance - mouseX);
        scrollContainer.scrollLeft -= speed;
      } else if (mouseX >= right - minDistance) {
        const speed = calculateScrollSpeed(mouseX - (right - minDistance));
        scrollContainer.scrollLeft += speed;
      }
    };

    scrollContainer.addEventListener("mousemove", handleMouseMove);

    return () => {
      scrollContainer.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // Map the data to dynamically populate the Muuri dashboard
  return (<>
    <Demo>
      <Main>
        <div className="max-h-[45vh] overflow-y-auto p-4 bg-gray-50 " ref={scrollContainerRef}>
          {data.map((group) => {
            const presentazion = presentations.find((item) => moment(item.groupe).isSame(moment(group.title), 'day'));
            const SpecialImages = presentazion ? presentazion.images.map((item)=> item._id) : [];
            const sortedItems = [...group.items].sort((a, b) => {
              const aIsSpecial = SpecialImages.includes(a.id) ? -1 : 1;
              const bIsSpecial = SpecialImages.includes(b.id) ? -1 : 1;
              return aIsSpecial - bIsSpecial;
            });

           return (
                  <div className="card mb-5" key={group.id}>

                    <div className="flex justify-between content-center p-4">
                      <h2 className="text-xl font-semibold ">Immagini caricate il {moment(group.title).format("DD/MM/YYYY")}</h2>

                      <button
                        type="button"
                        className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={()=>{ headelOpenImageModale(group.id)}}
                      >
                        Revisione immagini
                      </button>
                    </div>
                    
                    <Dashboard>
                      <MuuriComponent
                        id={group.id}
                        onSend={onSend}
                        {...getOptions(null, null, "move", scrollContainerRef , startDrag)}
                        onDragStart={(item, event) => handleDragStart(item, event)}
                        onDragMove={(item, event) => handleDragMove(item, event)}
                        onDragEnd={(item, event) => {
                          handleDragEnd(item, event, group.id);
                        }}
                      >
                        {/* Render the items for the current group */}
                        {sortedItems.map((item) => (
                          <Item key={item.id} idItem={item.id} content={item.content} group={group.id} settings={settings} setOpen={setOpen} setStartDrag={setStartDrag} setimageToMouve={setimageToMouve} SpecialImages={SpecialImages} />
                        ))}
                        
                      </MuuriComponent>
                    </Dashboard>
                  </div>
                )
          })}
        </div>
      </Main>
    </Demo>

    <Drawer open={open} setOpen={setOpen} Selectedpatient={patient} imageToMouve={imageToMouve} patients={patients} getAllConnection={getAllConnection}  />

    {openModalImage ? 
                <ModalImage
                    src={src}
                    url={url}
                    data={patientImages}
                    setData={setPatientImages}
                    checkboxState={checkboxState}
                    setCheckboxState={setCheckboxState}
                    imageType={imageType}
                    approvati={totApprovati}
                    nonApprovati={totNonApprovati}
                    setImageType={setImageType}
                    patient={patient}
                    setSrc={setSrc}
                    open={openModalImage}
                    setOpen={setOpenModalImage}
                    UpdateImageSate={UpdateImageSate}
                    finalloading={finalloading}
                />
            : null}

    </>);
};

const Item = React.memo(({ idItem,  content, group , settings , setOpen , setStartDrag , setimageToMouve , SpecialImages }) => {
  const url = settings.type === "SFTP" ? settings.url_pubblico + "/"+ settings.folderName +"/" : settings.url_pubblico;

  const { grid, id } = useGrid();

  const isDragging = useDrag();

  useRefresh([id]);

  const style = getDimensions(grid, id, isDragging);

  return (<>
        <div className={`${id}-item relative ${SpecialImages.includes(idItem) ? 'pointer-events-none' : ''} `} data-id={idItem} id={idItem} style={style} 
        onClick={(e)=>{if(e.target.id === idItem){setStartDrag(true)} }}
        >
            <div className="item-content relative" id={idItem}>
              <img
                alt={`item-${group}`}
                src={`${url}/compressioni/${content}`}
                loading="lazy"
                id={idItem}
                className={`content-img object-cover absolute bottom-0 left-0 rounded-md overflow-hidden outline-dashed outline-1 outline-offset-8 
                  ${SpecialImages.includes(idItem) ? 'opacity-20' : ''}`}
              />
            </div>

            {SpecialImages.includes(idItem) ? 
              null
            :
              <button
                  className={`absolute bottom-0 right-0 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 z-50  `}
                  //disabled={presentazionImagesIds.includes(idItem)}
                  onClick={(e) =>  { setimageToMouve({
                    id: idItem,
                    url: `${url}/compressioni/${content}`,
                  }); setOpen(prev => !prev)}} // Aggiungi la tua logica per il click
                >
                  <UsersIcon className="h-6 w-6 " />
              </button>
            }

        </div>



        </>);
});

export default App;
